import * as Sentry from '@sentry/browser'
import { WalletContextState } from '@solana/wallet-adapter-react'
import gtag from 'ga-gtag'

export const logEvent = (
  action: string,
  category: 'interaction' | 'view' | 'engagement',
  wallet?: WalletContextState,
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  params: any = {},
) => {
  try {
    if (wallet?.publicKey) {
      params.wallet = wallet.publicKey?.toBase58() || wallet.publicKey
    }

    gtag('event', action, {
      event_category: category,
      ...params,
    })
    Sentry.addBreadcrumb({
      category,
      message: action,
      level: action.includes('failure') ? 'error' : 'info',
      data: params,
    })
  } catch (error) {
    console.warn('error', error)
  }
}
