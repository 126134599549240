import { BN, web3 } from '@project-serum/anchor'
import { WalletContextState } from '@solana/wallet-adapter-react'
import { PromiEvent } from 'magic-sdk'
import { ReactNode } from 'react'
import { Area } from 'react-easy-crop/types'

export type PublicKeyString = string

export type UnixTimestamp = number

export type UnixTimestampString = string

export enum ContentNodeType {
  Release = 'release',
  Post = 'post',
  HubRelease = 'hub_release',
  HubPost = 'hub_post',
  Hub = 'hub',
  Gate = 'gate',
  Account = 'account',
  Artist = 'artist',
  SearchResult = 'search_result',
}

// RELEASES

export type Release = {
  index?: number
  type: ContentNodeType.Release | ContentNodeType.HubRelease
  publicKey: PublicKeyString
  mint: PublicKeyString
  metadata: ReleaseMetadataV1
  datetime: UnixTimestampString
  publishedThroughHub: PublicKeyString
  publisher: PublicKeyString
  publisherAccount: Account
  slug?: string
  hub?: Hub
  accountData?: {
    release: ReleaseAccountData
  }
}

export interface ScheduledRelease extends Release {
  uuid: string
  releaseDate: string
}

export interface ScheduledPost extends Post {
  uuid: string
  releaseDate: string
}

export type ReleaseAccountData = {
  payer: PublicKeyString
  authority: PublicKeyString
  authorityTokenAccount: PublicKeyString
  releaseSigner: PublicKeyString
  releaseMint: PublicKeyString
  releaseDatetime: number
  royaltyTokenAccount: PublicKeyString
  paymentMint: PublicKeyString
  totalSupply: number
  remainingSupply: number
  price: number
  resalePercentage: number
  totalCollected: number
  saleCounter: number
  exchangeSaleCounter: number
  saleTotal: number
  exchangeSaleTotal: number
  bumps: {
    release: number
    signer: number
  }
  head: number
  tail: number
  revenueShareRecipients: RevenueShareRecipient[]
  editionType: 'open' | 'limited'
}

export type ReleaseAccountDataSolana = {
  payer: web3.PublicKey
  authority: web3.PublicKey
  authorityTokenAccount: web3.PublicKey
  releaseSigner: web3.PublicKey
  releaseMint: web3.PublicKey
  royaltyTokenAccount: web3.PublicKey
  paymentMint: web3.PublicKey
  price: BN
}

export type HubAccountDataSolana = {
  handle: Uint8Array
}

export type RevenueShareRecipient = {
  recipientAuthority: PublicKeyString
  recipientTokenAccount: PublicKeyString
  percentShare: number
  owed: number
  collected: number
  handle: string
}

export type AudioFile = {
  uri: string
  track: number
  track_title: string
  duration: number
  type: string
}

export type ReleaseMetadataV1 = {
  name: string
  image: string
  symbol: string
  description: string
  external_url: string
  animation_url: string
  attributes: []
  collection: {
    name: string
    family: string
  }
  properties: {
    files: AudioFile[]
    tags: string[]
    artist: string
    title: string
    date: UnixTimestampString
    md5Digest: string
    category: 'audio'
  }
  descriptionHtml: string
}

export type QueueItem = {
  release: Release
  trackNumber: number
}

export type FetchReleaseResponse = {
  release: Release
  message?: string
}

export type FetchReleasesResponse = {
  releases: Release[]
  total?: number
  message?: string
}

export type ReleaseRecommendation = {
  publicKey: PublicKeyString
  mint: PublicKeyString
  metadata: ReleaseMetadataV1
  datetime: UnixTimestampString
  publishedThroughHub: PublicKeyString
  publisher: PublicKeyString
  type?: ContentNodeType.Release | ContentNodeType.HubRelease
  hub?: Hub
}

export type FetchRecommendedReleasesForReleaseResponse = {
  success: boolean
  data: {
    releasePublicKey: PublicKeyString
    recommendations: ReleaseRecommendation[]
  }
}

export type ReleaseCode = {
  id: number
  createdBy: PublicKeyString
  code: string
  createdAt: UnixTimestampString
  claimedAt: UnixTimestampString | null
  claimedBy: Account
  release: PublicKeyString
}

// HUBS

export type FetchHubResponse = {
  hub: Hub
  collaborators?: HubCollaborator[]
  releases?: HubRelease[]
  posts?: HubPost[] | Article[]
}

export type FetchHubsResponse = {
  hubs: Hub[]
  total?: number
  message?: string
}

export interface HubRelease extends Release {
  type: ContentNodeType.HubRelease
  hubReleasePublicKey: PublicKeyString
  visible: boolean
  accountData?: {
    release: ReleaseAccountData
    hubRelease: HubReleaseAccountData
    hubContent: HubContentAccountData
  }
}

export type Hub = {
  index?: number
  type: ContentNodeType
  publicKey: PublicKeyString
  handle: string
  data: HubData
  datetime: UnixTimestampString
  dataUri: string
  updatedAt: UnixTimestampString | null
  authority: PublicKeyString
  accountData?: {
    hub: HubAccountData
    hubSigner?: PublicKeyString
  }
}

export type HubData = {
  displayName: string
  description: string
  extrnalUrl: string
  image: string
  descriptionHtml: string
}

export type HubAccountData = {
  authority: PublicKeyString
  hubSigner: PublicKeyString
  handle: string
  uri: string
  publishFee: number
  referralFee: number
  totalFeesEarnerd: number
  hubSignerBump: number
  datetime: UnixTimestamp
}

export type HubReleaseAccountData = {
  hub: PublicKeyString
  release: PublicKeyString
  sales: number
  publicKey: PublicKeyString
}

export interface HubCollaborator extends Account {
  hubCollaboratorPublicKey: PublicKeyString
  accountData?: {
    collaborator: HubCollaboratorAccountData
  }
}

export type HubCollaboratorAccountData = {
  addedBy: PublicKeyString
  hub: PublicKeyString
  allowance: number
  collaborator: PublicKeyString
  canAddContent: boolean
  canAddCollaborator: boolean
  datetime: UnixTimestamp
  publicKey: PublicKeyString
}

export interface HubPost extends Post {
  type: ContentNodeType.Post | ContentNodeType.HubPost
  publicKey: PublicKeyString
  data: HubPostData
  datetime: UnixTimestampString
  hubPostPublicKey: PublicKeyString
  publisher: PublicKeyString
  publishedThroughHub: PublicKeyString
  hub: Hub
  heroImage?: string
  accountData?: {
    post: PostAccountData
    hubPost: HubPostAccountData
    hubContent: HubContentAccountData
  }
}

export type HubPostData = {
  title: string
  body: string
  slug: string
  reference: PublicKeyString
  bodyHtml: string
  heroImage?: string
}

export type PostAccountData = {
  author: PublicKeyString
  createdAt: number
  updatedAt: number | null
  slug: string
  uri: string
}

export type HubPostAccountData = {
  hub: PublicKeyString
  post: PublicKeyString
  referenceContent: PublicKeyString
  versionUri: string
  referenceContentType: HubContentType
  publicKey: PublicKeyString
}

export type HubContentAccountData = {
  addedBy: PublicKeyString
  hub: PublicKeyString
  child: PublicKeyString
  contentType: HubContentType
  datetime: UnixTimestamp
  visible: boolean
  publishedThroughHub: boolean
  repostedFromHub: PublicKeyString
  publicKey: PublicKeyString
}

export type HubContentType = 'ninaReleaseV1' | 'post'

// ACCOUNTS

export type FetchAccountsResponse = {
  accounts?: Account[]
  total?: number
  message?: string
}

export type FetchAccountResponse = {
  collected: Release[]
  published: Release[]
  hubs: Hub[]
  posts: Post[]
  exchanges: Exchange[]
  revenueShares: RevenueShareRecipient[]
  subscriptions: Subscription[]
  verifications: AccountVerification[]
}

export type Account = {
  publicKey: PublicKeyString
  verifications?: AccountVerification[]
  displayName?: string
  image?: string
  handle?: string
  description?: string
  type?: ContentNodeType.Account
}

export interface AccountWithRevenueShare extends Account {
  accountData?: {
    revenueShareRecipient: RevenueShareRecipient
  }
}

export type Collector = {
  collectedDate: UnixTimestampString
  collection: PublicKeyString[]
  publicKey: PublicKeyString
  verifications: AccountVerification[]
}

export type VerificationSource =
  | 'twitter'
  | 'soundcloud'
  | 'ethereum'
  | 'instagram'

export type VerificationTypes = {
  type: VerificationSource
  link: string | null
}

export type AccountVerification = {
  publicKey: PublicKeyString
  type: VerificationSource
  value: string
  displayName: string
  image: string
  description: string | null
  active: boolean
  account: PublicKeyString
}

export type Follower = {
  account: Account
  subscription: Subscription
}

export type Following = {
  hub?: Hub
  account?: Account
  subscription: Subscription
}

export type HubFollower = {
  account: Account
  subscription: Subscription
}

// EXCHANGES

export type FetchExchangesResponse = {
  exchange?: Exchange[]
  total?: number
  message?: string
}

export type Exchange = {
  publicKey: PublicKeyString
  isSale: boolean
  expectedAmount: string
  initializerAmount: string
  cancelled: boolean
  createdAt: UnixTimestampString
  updatedAt: UnixTimestampString | null
  completedBy?: Account
  release: PublicKeyString
  initializer: Account
}

// POSTS

export type FetchPostsResponse = {
  posts?: Post[]
  total?: number
  message?: string
}

export type Post = {
  type: ContentNodeType.Post | ContentNodeType.HubPost
  publicKey: PublicKeyString
  data: PostData
  datetime: UnixTimestampString
  publisher: PublicKeyString
  publishedThroughHub: PublicKeyString
  hub: Hub
}

export type PostData = {
  title: string
  body: string
  slug: string
  reference: PublicKeyString
  bodyHtml: string
  heroImage?: string
}

export type AllContent = {
  all: AllContentData[]
}

export type AllContentData = {
  publicKey: PublicKeyString
  mint?: PublicKeyString
  metadata?: ReleaseMetadataV1
  data?: PostData | ReleaseMetadataV1 | HubData
  datetime: UnixTimestampString
  hubPostPublicKey?: PublicKeyString
  hubReleasePublicKey?: PublicKeyString
  hubContentPublicKey?: PublicKeyString
  visible?: boolean
  publisher?: PublicKeyString
  publishedThroughHub?: PublicKeyString
  type: ContentNodeType
  handle?: string
  hub?: Hub
  dataUri?: string
  updatedAt?: UnixTimestampString
  authority?: PublicKeyString
}

// SUBSCRIPTIONS

export type FetchSubscriptionsResponse = {
  subscriptions?: Subscription[]
  total?: number
  message?: string
}

export type Subscription = {
  publicKey: PublicKeyString
  datetime: UnixTimestampString
  from: Account
  to: Account | Hub
  subscriptionType: 'account' | 'hub'
}

// SEARCH

export type SearchResults = {
  all: (SearchResultsRelease | SearchResultsHub | SearchResultsAccount)[]
  releases: SearchResultsRelease[]
  hubs: SearchResultsHub[]
  articles: []
  accounts: SearchResultsAccount[]
}

export type SearchResultsAccount = {
  account: PublicKeyString
  active: boolean
  description: string
  displayName: string
  image: string
  publicKey: PublicKeyString
  type: ContentNodeType.Account
  value: string
}

export type SearchResultsArtist = {
  account: Account
  name: string
  publishesAs: string[]
  type?: ContentNodeType.Artist
}

export type SearchResultsHub = {
  displayName: string
  handle: string
  image: string
  publicKey: PublicKeyString
  accountData?: {
    hub: HubAccountData
  }
  data?: HubData
  type: ContentNodeType.Hub
}

export type SearchResultsRelease = {
  artist: string
  image: string
  publicKey: PublicKeyString
  title: string
  accountData?: { release: ReleaseAccountData }
  type: ContentNodeType.Release
}

// PAGINATION

export type PaginationProps = {
  limit: number
  offset: number
  total?: number
  sort?: string
  tab?: string
  query?: string
  column?: string
}

// GATES

export type Gate = {
  id: number
  description: string
  fileName: string
  fileSize: number
  uri: string
  releasePublicKey: PublicKeyString
  type?: ContentNodeType.Gate
}

export type FetchGatesResponse = {
  gates: Gate[]
}

//FORMS

export interface FormProps extends React.HTMLAttributes<HTMLFormElement> {
  formSteps?: { [key: string]: FormStep }
  fields: FormField[]
  setFields?: React.Dispatch<React.SetStateAction<FormField[]>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit?: (e: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange?: any
  handleChangeWithoutEvent?: (
    e: React.ChangeEvent<HTMLElement> | null,
    data?: {
      name?: string
      value: string
    },
  ) => void
  handleClose?: () => void
  handleCroppedImage?: (data: {
    imageSrc: string
    croppedAreaPixels: Area
    file: Blob | MediaSource | undefined | File
  }) => void
  handleTagFieldChange?: (name: string, tags: string[]) => void
  removeFileFromList?: (index: number) => void
  reorderFileInList?: (file: FileExtended, from: number, to: number) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleImageSelect?: any
  title?: string
  buttonLabel?: string
  currentStep?: number
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>>
  isInModal?: boolean
  inputMarginStyleOverride?: string
  buttonStyleOverride?: string
  isRound?: boolean
  additionalComponent?: React.ReactNode
  allowCancel?: boolean
  cancelLabel?: string
  handleCancel?: () => void
  block?: Block
  accountHubs?: Hub[]
  imageFieldReset?: () => void
  inSettings?: boolean
  disableSubmit?: boolean
  submittingLabel?: string
  hideButtons?: boolean
}

// TO DO - implement this
export enum FormFieldTypes {
  Text = 'text',
  TextArea = 'textarea',
  Range = 'range',
  FileUpload = 'fileUpload',
  Select = 'select',
  Conditional = 'conditional',
  ImageUpload = 'imageUpload',
  Number = 'number',
  Radio = 'radio',
  Review = 'review',
  TrackTitle = 'trackTitle',
  Date = 'date',
  Checkbox = 'checkbox',
  MultiFileUpload = 'multiFileUpload',
}

export type FormField = {
  name: string
  label: string
  type:
    | 'text'
    | 'textarea'
    | 'range'
    | 'fileUpload'
    | 'select'
    | 'conditional'
    | 'imageUpload'
    | 'image'
    | 'number'
    | 'radio'
    | 'review'
    | 'trackTitle'
    | 'date'
    | 'checkbox'
    | 'hubSelect'
    | 'multiFileUpload'
    | 'tags'
  placeholder?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any
  required: boolean
  inputProps?: InputProps
  disabled?: boolean
  hint?: string
  hide?: boolean
  error?: (string | undefined | boolean)[]
  handleChange?:
    | ((e: React.ChangeEvent<HTMLElement>) => void)
    | ((e: React.ChangeEvent<HTMLInputElement>) => void) // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | any
  handleChangeWithoutEvent?: (
    e: React.ChangeEvent<HTMLElement> | null,
    data?: {
      name?: string
      value: string
    },
  ) => void
  handleTagFieldChange?: (name: string, tags: string[]) => void
  flowType?: FlowType
  styleOverride?: string
}

export type ReleaseCreateFormFieldData = {
  artwork: {
    file: File
    preview: string
  }
  catalogNumber: string
  description: string
  hub: string
  retailPrice: number
  amount: number
  resalePercentage: number
  title: string
  tracks: FileExtended[]
  date: string
  tags: string[]
  isUsdc: boolean
}

export type ArticleCreateFormFieldData = {
  heroImage?: ArticleCreateHeroImage
  title: string
  subhead: string
  hub: string
  date: string
  author: string
  authorDisplayName: string
  description: string
  blocks: Block[]
  tags: string[]
  slug: string
}

export type Article = {
  heroImage: ArticleCreateHeroImage
  title: string
  subhead: string
  hub: string
  date: string
  author: string
  authorDisplayName: string
  description: string
  blocks: Block[]
}

export type ArticleCreateHeroImage = {
  file?: File
  preview?: string
}

export type FetchArticleResponse = {
  publicKey: string
  data: {
    heroImage: ArticleCreateHeroImage
    title: string
    subhead: string
    hub: string
    date: string
    blocks: Block[]
  }
  publishedThroughHub?: string
  publisher?: string
  type?: ContentNodeType
  hub?: Hub
  version?: string
}

export type HubCreateFormFieldData = {
  name: string
  permalink: string
  image: {
    file: File
    preview: string
  }
  description: string
}

export type HubUpdateFormFieldData = {
  name: string
  image: {
    file: File | ImageFile | string | ImageCropFile
    preview: string
  }
  description: string
}

// export type CroppedImage = string

export type InputProps = {
  dependentOn?: string
  dependencyTrigger?: string | boolean
  type?: string
  currency?: string
  inputSelectOptions?: { label: string; value: string }[]
  min?: number
  max?: number
  secondaryDependency?: string
  secondaryDependencyTrigger?: string | boolean
  childInput?: ChildInputProps
  siblingInput?: SiblingInputProps
  aspect?: number
  uploadType?: string
  endIcon?: React.ReactNode
  styleOverride?: string
  variant?: string
  accentColor?: string
  fileSizeLimit?: number
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  inputRef?: React.RefObject<HTMLElement> | React.LegacyRef<HTMLElement> | any
  validate?: (
    target: React.ChangeEvent<HTMLElement>,
  ) => boolean | string | undefined
  options?: {
    [key: string]: {
      label: string
      type: string
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      callback: (value, index) => void
    }
  }
}

export type ChildInputProps = {
  name?: string
  type: 'checkbox' | 'select' | 'display'
  label?: string
  value?: boolean | string | number | undefined
  options?: {
    [key: string]: {
      label: string
      type: string
    }
  }
}

export type SiblingInputProps = {
  type: 'button' | 'select'
  label?: string
  value?: boolean | string | number | undefined
  name?: string
  options?: {
    [key: string]: {
      label: string
      type: string
    }
  }
}

export type FormStep = {
  fields: string[]
  buttonLabel: string
  hideButtons?: boolean
  hideBackButton?: boolean
  onChangeCallback: (
    fields: FormField[],
    setFields: React.Dispatch<React.SetStateAction<FormField[]>>,
    validationSchema?: object,
  ) => Promise<boolean>
}

export type FormSteps = { [key: string]: FormStep }

export type ImageCropProps = {
  imageSrc: string
  file?: ImageCropFile
  croppedAreaPixels?: {
    x: number
    y: number
    width: number
    height: number
  }
  croppedImage: string | undefined
}

export type ImageCropFile = {
  file: ImageFile
  preview: string
  name?: string
  type?: string
}

export type ImageFile = {
  lastModified: number
  lastModifiedDate: Date
  name: string
  size: number
  type: string
}

// NOTIFICATIONS

export type FetchNotificationsResponse = {
  success: boolean
  unviewed: number
  notificationItems: NotificationItem[]
  total: number
}

export type NotificationItem = {
  txid: string
  blocktime: number
  type: NotificationType
  authority: Account
  hub?: Hub
  release?: Release
  toAccount?: Account
  toHub?: Hub
  post?: Post
  datetime: UnixTimestampString
}

// FEED

export type FetchFeedResponse = {
  feedItems: FeedItem[]
  total: number
}

export type FeedItem = {
  authority: Account
  blocktime: number
  datetime: UnixTimestampString
  release?: Release
  hub?: Hub
  post?: Post
  txid: string
  type: NotificationType
  toAccount?: Account
  toHub?: Hub
}

// PLAY HISTORY
export type FetchPlayHistoryResponse = {
  success: boolean
  plays: PlayHistoryItem[]
  total: number
}

export type PlayHistoryItem = {
  release: Release
  playedAt: UnixTimestampString
}
//TOAST

export type ToastVariant =
  | 'success'
  | 'error'
  | 'processing'
  | 'loading'
  | 'uploadComplete'
  | string

export type Toast = {
  message: string
  variant: ToastVariant
  id: string
  image?: string
  link?: string
  handleDeleteToast?: (id: string) => void
}

export enum View {
  List = 'list',
  Grid = 'grid',
  Header = 'header',
}

export enum ListBoxView {
  List = 'list',
  Grid = 'grid',
  ReleaseHeader = 'release-header',
  ReleaseList = 'release-list',
}

export type ListBoxOption = {
  title: string
  type: string
  callback: () => void
}

export enum ReleaseListItemVariant {
  Default = 'default',
  Embed = 'embed',
}

export enum ContentType {
  Release = 'release',
  Post = 'post',
}

export enum ContentTabType {
  Mixed,
  Releases,
  ReleasesPublishedByUser,
  ReleasesCollectedByUser,
  Articles,
  Hubs,
  Collection,
  MoreFromHub,
  MorePostsFromHub,
  MoreFromUser,
  Recommendations,
  Tracklist,
  RepostedToHub,
  MentionedIn,
}

export enum ContentTabSource {
  Hub,
  Profile,
  Search,
  Release,
  PlayHistory,
  Post,
}

export enum FilterType {
  // Source = 'Source',
  Sort = 'Sort',
}

export enum NotificationType {
  ReleasePurchase = 'ReleasePurchase',
  ReleasePurchaseViaHub = 'ReleasePurchaseViaHub',
  SubscriptionSubscribeHub = 'SubscriptionSubscribeHub',
  SubscriptionSubscribeAccount = 'SubscriptionSubscribeAccount',
  ReleaseInitViaHub = 'ReleaseInitViaHub',
  HubAddCollaborator = 'HubAddCollaborator',
  HubAddRelease = 'HubAddRelease',
  ReleaseClaim = 'ReleaseClaim',
  ReleaseRevenueShareCollect = 'ReleaseRevenueShareCollect',
  ReleaseRevenueShareTransfer = 'ReleaseRevenueShareTransfer',
}

export enum FlowType {
  Release = 'release',
  Post = 'post',
  Hub = 'hub',
  Article = 'article',
  ArticleCreate = 'articleCreate',
}

export enum TrackListType {
  Queue = 'queue',
  History = 'history',
}

// Makes HTMLAttributes extendable for non-standard input elements (content editable divs)
declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    name?: string
    type?: string
  }
}

export interface SupplementalTabContent {
  [object: string]: {
    label: string
    content?: ReactNode
    modalType?: ModalType
    modalLabelVariant?: ButtonVariant
    href?: string
    modalHeader?: string
    searchInput?: boolean
    searchInputPlaceholder?: string
  }
}

// MODAL PROP TYPES

export enum ModalType {
  Default = 'default',
  Login = 'login',
  ViewAll = 'ViewAll',
  Wallet = 'Wallet',
}

//  BUTTON PROP TYPES

export enum ButtonColor {
  Solid = 'solid',
  Blue = 'blue',
  Black = 'black',
  Grey = 'grey',
  White = 'white',
  Warning = 'warning',
  Unstyled = 'unstyled',
}

export enum ButtonFont {
  Body1 = 'body-1',
  UI1 = 'ui-1',
  Caption1 = 'caption-1',
}

export enum ButtonVariant {
  Unstyled = 'unstyled',
  Large = 'large',
  Small = 'small',
  Square = 'square',
  Icon = 'icon',
  SquareBorderWhite = 'square-border-white',
}

export enum ButtonResponsiveness {
  Mobile = 'mobile',
  Full = 'full',
  MinW = 'min-w',
}

export interface TabbedPageURLSearchParams {
  tab: string
  view: string
  page: number
  column: string
  sort: string
  query: string
}

export enum PlayType {
  Now,
  Next,
  Later,
  Initial,
}

export type Login = {
  on: (event: string, callback: () => void) => void
  emit: (event: string, data?: unknown) => void
}

export type LoginOrPromiEvent =
  | Login
  | PromiEvent<
      string | null,
      {
        'email-sent': () => void
        'email-not-deliverable': () => void
        retry: () => void
      } & {
        'device-needs-approval': () => void
        'device-verification-email-sent': () => void
        'device-verification-link-expired': () => void
        'device-approved': () => void
        'device-retry': () => void
      } & {
        done: (result: string | null) => void
        error: (reason: Error) => void
        settled: () => void
      }
    >

export type Favorite = {
  account?: Account
  release?: Release
  post?: Post
  createdAt: UnixTimestampString
  updatedAt: UnixTimestampString
  favoriteType: FavoriteType
  publicKey: PublicKeyString
}

export enum FavoriteType {
  Release = 'release',
  Post = 'post',
}

export enum FavoriteButtonVariant {
  ReleaseList = 'releaseList',
}

export type FavoriteButtonSource =
  | 'releaseHeader'
  | 'releaseList'
  | 'featuredReleaseInArticle'
  | 'audioPlayer'

export type Block = {
  type: string
  label?: string
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  data: string | number | boolean | any
  index: number
}

export type BlockOptions = {
  [key: string]: BlockOption
}

export type BlockOption = {
  label: string
  type: string
  data?: any
}

export type AccountPageProps = {
  params: { accountPublicKey: string }
  searchParams: TabbedPageURLSearchParams
}

export type AccountComponentProps = {
  accountPublicKey: PublicKeyString
  searchParams: TabbedPageURLSearchParams
}

export type DashboardComponentProps = {
  searchParams: TabbedPageURLSearchParams
}

export type InputSelectOptionsProps = {
  [key: string]: InputSelectOptionProps
}

export type InputSelectOptionProps = {
  label: string
  type?: string
  data?: string | number | boolean | object | undefined
  callback?: () => void
}

export interface FileExtended extends File {
  title: string
  trackNumber: number
  description: string
  fileNumber: number
  fileName: string
  fileSize: number
}

export type Profile = {
  account: Account
  handle: string
  createdAt: string
  description: string
  displayName: string
  image: string
  publicKey: string
  admin: boolean
}

export interface ProfileAuthorized extends Profile {
  email: string
  emailIsVerified: boolean
  emailNotificationNewActivity: string
  emailNotificationNewActivityFollowing: string
}

export type TxResponse = {
  data: { error?: any; message: string; txid?: string }
}

export enum NinaProgramAction {
  HUB_ADD_COLLABORATOR = 'HUB_ADD_COLLABORATOR',
  HUB_ADD_RELEASE = 'HUB_ADD_RELEASE',
  HUB_INIT_WITH_CREDIT = 'HUB_INIT_WITH_CREDIT',
  HUB_UPDATE = 'HUB_UPDATE',
  POST_INIT_VIA_HUB_WITH_REFERENCE_RELEASE = 'POST_INIT_VIA_HUB_WITH_REFERENCE_RELEASE',
  POST_INIT_VIA_HUB = 'POST_INIT_VIA_HUB',
  RELEASE_INIT_VIA_HUB = 'RELEASE_INIT_VIA_HUB',
  RELEASE_INIT_WITH_CREDIT = 'RELEASE_INIT_WITH_CREDIT',
  RELEASE_PURCHASE = 'RELEASE_PURCHASE',
  RELEASE_PURCHASE_VIA_HUB = 'RELEASE_PURCHASE_VIA_HUB',
  EXCHANGE_INIT = 'EXCHANGE_INIT',
  EXCHANGE_ACCEPT = 'EXCHANGE_ACCEPT',
  CONNECTION_CREATE = 'CONNECTION_CREATE',
  SUBSCRIPTION_SUBSCRIBE_HUB = 'SUBSCRIPTION_SUBSCRIBE_HUB',
  SUBSCRIPTION_SUBSCRIBE_ACCOUNT = 'SUBSCRIPTION_SUBSCRIBE_ACCOUNT',
}

export type PendingUpload = {
  date: string
  type: 'release' | 'post'
  status:
    | 'uploading'
    | 'processing'
    | 'scheduled'
    | 'done'
    | 'error'
    | 'preparing'
    | 'transferring_credit'
  imagePreview: string
  title: string
  publicKey?: PublicKeyString
  uploadProgress?: number
  slug?: string
  total?: number
  estimated?: number
  message?: string
}

export type EventCategory = 'interaction' | 'view' | 'engagement'

export type EventWrapperProps = {
  action: string
  category: EventCategory
  wallet?: WalletContextState
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  params?: any
  className?: string
}

export type AccountHeaderTotals = {
  published: number | undefined
  collected: number | undefined
  hubs: number | undefined
  posts: number | undefined
  favorites: number | undefined
}

export type ReleaseConnectionsHeaderTotals = {
  releaseCollectors: number | undefined
  releaseFavorites: number | undefined
  repostedTo: number | undefined
  mentionedIn: number | undefined
}

export type HubInfoTabHeaderTotals = {
  collaborators: number | undefined
  hubFollowers: number | undefined
}

export type HeaderTotals = { [key: string]: number | undefined }
