type Props = {
  className?: string
  height?: number
  width?: number
}

export default function BulletListIcon({
  className,
  height = 20,
  width = 20,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-${className}`}
    >
      <path d="M8 10H16" stroke="#BFBFBF" strokeWidth="1.25" />
      <path d="M8 5.5H16" stroke="#BFBFBF" strokeWidth="1.25" />
      <path d="M8 14.5H16" stroke="#BFBFBF" strokeWidth="1.25" />
      <circle cx="5" cy="10" r="1" fill="#BFBFBF" />
      <circle cx="5" cy="5.5" r="1" fill="#BFBFBF" />
      <circle cx="5" cy="14.5" r="1" fill="#BFBFBF" />
    </svg>
  )
}
