type Props = {
  className: string
  height?: number
  width?: number
}

export default function Plus({ className, height, width }: Props) {
  return (
    <svg
      className={`stroke-${className}`}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 3.19922V12.7992" stroke={className} strokeWidth="1.25" />
      <path d="M3.19971 8L12.7997 8" stroke={className} strokeWidth="1.25" />
    </svg>
  )
}
