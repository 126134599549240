type Props = {
  className: string
  height?: number
  width?: number
}

export default function Download({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      className={`fill-${className}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 16H5V14.75H15V16Z"
        fill={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55804 10.4419L6.44193 9.55804L9.99999 13.1161L13.558 9.55804L14.4419 10.4419L9.99999 14.8839L5.55804 10.4419Z"
        fill={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.375 14L9.375 4L10.625 4L10.625 14L9.375 14Z"
        fill={className}
      />
    </svg>
  )
}
