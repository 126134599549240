type Props = {
  className: string
  height?: number
  width?: number
}

export default function QueueActive({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0H0V20H20V0ZM4 4H5.5V5.49993H4V4ZM16 5.37496H7.5V4.12496H16V5.37496ZM5.5 7.5H4V8.99993H5.5V7.5ZM16 8.87496H7.5V7.62496H16V8.87496ZM5.5 11.0001H4V12.5H5.5V11.0001ZM16 12.375H7.5V11.125H16V12.375ZM5.5 14.5001H4V16H5.5V14.5001ZM16 15.875H7.5V14.625H16V15.875Z"
        fill={className}
      />
    </svg>
  )
}
