type Props = {
  className: string
  height?: number
  width?: number
}

export default function ChevronUpHeavy({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8.3839L5.44199 12.942L4.55811 12.0581L10 6.61613L15.442 12.0581L14.5581 12.942L10 8.3839Z"
        fill={className}
      />
    </svg>
  )
}
