import BoldIcon from '@/public/icons/boldIcon'
import BulletListIcon from '@/public/icons/bulletListIcon'
import ChevronDownHeavy from '@/public/icons/chevronDownHeavy'
import ChevronDownLight from '@/public/icons/chevronDownLight'
import ChevronLeftHeavy from '@/public/icons/chevronLeftHeavy'
import ChevronLeftLight from '@/public/icons/chevronLeftLight'
import ChevronRightHeavy from '@/public/icons/chevronRightHeavy'
import ChevronRightLight from '@/public/icons/chevronRightLight'
import ChevronUpHeavy from '@/public/icons/chevronUpHeavy'
import ChevronUpLight from '@/public/icons/chevronUpLight'
import CloseLarge from '@/public/icons/closeLarge'
import CloseSmall from '@/public/icons/closeSmall'
import Download from '@/public/icons/download'
import Ellipsis from '@/public/icons/ellipsis'
import EmptyCheckbox from '@/public/icons/emptyCheckbox'
import FilledCheckbox from '@/public/icons/filledCheckbox'
import GridView from '@/public/icons/gridView'
import Hamburger from '@/public/icons/hamburger'
import Handle from '@/public/icons/handle'
import ItalicIcon from '@/public/icons/italicIcon'
import LinkIcon from '@/public/icons/linkIcon'
import ListView from '@/public/icons/listView'
import Lock from '@/public/icons/lock'
import Minus from '@/public/icons/minus'
import Next from '@/public/icons/next'
import PauseLarge from '@/public/icons/pauseLarge'
import PauseLeftSmall from '@/public/icons/pauseLeftSmall'
import PauseSmall from '@/public/icons/pauseSmall'
import PlayLarge from '@/public/icons/playLarge'
import PlayLeftSmall from '@/public/icons/playLeftSmall'
import PlaySmall from '@/public/icons/playSmall'
import Plus from '@/public/icons/plus'
import Previous from '@/public/icons/previous'
import Queue from '@/public/icons/queue'
import QueueActive from '@/public/icons/queueActive'
import Repost from '@/public/icons/repost'
import SearchLarge from '@/public/icons/searchLarge'
import SearchSmall from '@/public/icons/searchSmall'
import StarFilled from '@/public/icons/starFilled'
import StarOutlined from '@/public/icons/starOutlined'
import Station from '@/public/icons/station'
import Volume from '@/public/icons/volume'
import VolumeMuted from '@/public/icons/volumeMuted'

export interface IconProps {
  name: string
  className: string
  height?: number
  width?: number
}

export default function Icon({
  name,
  className,
  height = 20,
  width = 20,
}: IconProps) {
  switch (name) {
    case 'boldIcon':
      return <BoldIcon className={className} height={height} width={width} />
    case 'bulletListIcon':
      return (
        <BulletListIcon className={className} height={height} width={width} />
      )

    case 'chevronDownHeavy':
      return (
        <ChevronDownHeavy className={className} height={height} width={width} />
      )
    case 'chevronDownLight':
      return (
        <ChevronDownLight className={className} height={height} width={width} />
      )
    case 'chevronLeftHeavy':
      return (
        <ChevronLeftHeavy className={className} height={height} width={width} />
      )
    case 'chevronLeftLight':
      return (
        <ChevronLeftLight className={className} height={height} width={width} />
      )
    case 'chevronRightHeavy':
      return (
        <ChevronRightHeavy
          className={className}
          height={height}
          width={width}
        />
      )
    case 'chevronRightLight':
      return (
        <ChevronRightLight
          className={className}
          height={height}
          width={width}
        />
      )
    case 'chevronUpHeavy':
      return (
        <ChevronUpHeavy className={className} height={height} width={width} />
      )
    case 'chevronUpLight':
      return (
        <ChevronUpLight className={className} height={height} width={width} />
      )
    case 'closeLarge':
      return <CloseLarge className={className} height={height} width={width} />
    case 'closeSmall':
      return <CloseSmall className={className} height={height} width={width} />
    case 'download':
      return <Download className={className} height={height} width={width} />
    case 'emptyCheckbox':
      return (
        <EmptyCheckbox className={className} height={height} width={width} />
      )
    case 'filledCheckbox':
      return (
        <FilledCheckbox className={className} height={height} width={width} />
      )
    case 'ellipsis':
      return <Ellipsis className={className} height={height} width={width} />
    case 'gridView':
      return <GridView className={className} height={height} width={width} />
    case 'hamburger':
      return <Hamburger className={className} height={height} width={width} />
    case 'handle':
      return <Handle className={className} height={height} width={width} />
    case 'italicIcon':
      return <ItalicIcon className={className} height={height} width={width} />
    case 'linkIcon':
      return <LinkIcon className={className} height={height} width={width} />
    case 'listView':
      return <ListView className={className} height={height} width={width} />
    case 'lock':
      return <Lock className={className} height={height} width={width} />
    case 'next':
      return <Next className={className} height={height} width={width} />
    case 'pauseLarge':
      return <PauseLarge className={className} height={height} width={width} />
    case 'pauseLeftSmall':
      return (
        <PauseLeftSmall className={className} height={height} width={width} />
      )
    case 'pauseSmall':
      return <PauseSmall className={className} height={height} width={width} />
    case 'playLarge':
      return <PlayLarge className={className} height={height} width={width} />
    case 'playLeftSmall':
      return (
        <PlayLeftSmall className={className} height={height} width={width} />
      )
    case 'playSmall':
      return <PlaySmall className={className} height={height} width={width} />
    case 'previous':
      return <Previous className={className} height={height} width={width} />
    case 'queue':
      return <Queue className={className} height={height} width={width} />
    case 'queueActive':
      return <QueueActive className={className} height={height} width={width} />
    case 'repost':
      return <Repost className={className} height={height} width={width} />
    case 'searchLarge':
      return <SearchLarge className={className} height={height} width={width} />
    case 'searchSmall':
      return <SearchSmall className={className} height={height} width={width} />
    case 'starFilled':
      return <StarFilled className={className} height={height} width={width} />
    case 'starOutlined':
      return (
        <StarOutlined className={className} height={height} width={width} />
      )
    case 'station':
      return <Station className={className} height={height} width={width} />
    case 'volume':
      return <Volume className={className} height={height} width={width} />
    case 'volumeMuted':
      return <VolumeMuted className={className} height={height} width={width} />
    case 'minus':
      return <Minus className={className} height={height} width={width} />
    case 'plus':
      return <Plus className={className} height={height} width={width} />
    default:
      return <div>Icon not found</div>
  }
}
