type Props = {
  className: string
  height?: number
  width?: number
}

export default function chevronRightHeavy({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      className={`fill-${className}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6162 9.99999L7.05811 5.44193L7.94199 4.55804L13.3839 9.99999L7.94199 15.4419L7.05811 14.558L11.6162 9.99999Z"
        fill={className}
      />
    </svg>
  )
}
