type Props = {
  className: string
  height?: number
  width?: number
}

export default function EmptyCheckbox({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="0.5" y="0.5" width="11" height="11" stroke="black" />
    </svg>
  )
}
