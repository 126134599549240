type Props = {
  className: string
  height?: number
  width?: number
}

export default function GridView({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8838 1.11618H12.8662V7.13382H18.8838V1.11618ZM11.75 0V8.25H20V0H11.75Z"
        fill={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.13382 1.11618H1.11618V7.13382H7.13382V1.11618ZM0 0V8.25H8.25V0H0Z"
        fill={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8838 12.8662H12.8662V18.8838H18.8838V12.8662ZM11.75 11.75V20H20V11.75H11.75Z"
        fill={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.13382 12.8662H1.11618V18.8838H7.13382V12.8662ZM0 11.75V20H8.25V11.75H0Z"
        fill={className}
      />
    </svg>
  )
}
