type Props = {
  className: string
  height?: number
  width?: number
}

export default function SearchLarge({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.71875 15.1875C12.2913 15.1875 15.1875 12.2913 15.1875 8.71875C15.1875 5.14616 12.2913 2.25 8.71875 2.25C5.14616 2.25 2.25 5.14616 2.25 8.71875C2.25 12.2913 5.14616 15.1875 8.71875 15.1875ZM8.71875 16.4375C12.9817 16.4375 16.4375 12.9817 16.4375 8.71875C16.4375 4.4558 12.9817 1 8.71875 1C4.4558 1 1 4.4558 1 8.71875C1 12.9817 4.4558 16.4375 8.71875 16.4375Z"
        fill={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4419 13.558L19.4419 18.558L18.558 19.4419L13.558 14.4419L14.4419 13.558Z"
        fill={className}
      />
    </svg>
  )
}
