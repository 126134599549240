type Props = {
  className: string
  height?: number
  width?: number
}

export default function ChevronLeftHeavy({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      className={`fill-${className}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.38384 10L12.9419 14.5581L12.058 15.442L6.61607 10L12.058 4.55807L12.9419 5.44196L8.38384 10Z"
        fill={className}
      />
    </svg>
  )
}
