type Props = {
  className: string
  height?: number
  width?: number
}

export default function StarOutlined({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2451 7.40983L10 0.5L7.75487 7.40983H0.489441L6.36729 11.6803L4.12215 18.5902L10 14.3197L15.8779 18.5902L13.6327 11.6803L19.5106 7.40983H12.2451ZM15.9712 8.55983H11.4096L10 4.22148L8.59039 8.55983H4.02878L7.7192 11.2411L6.30958 15.5794L10 12.8982L13.6904 15.5794L12.2808 11.2411L15.9712 8.55983Z"
        fill={className}
      />
    </svg>
  )
}
