type Props = {
  className: string
  height?: number
  width?: number
}

export default function FilledCheckbox({ className, height, width }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      className={className}
    >
      <rect width="12" height="12" fill="black" />
      <path d="M1.5 6.5L4 9L10.5 2.5" stroke="white" strokeWidth="1.25" />
    </svg>
  )
}
