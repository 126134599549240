type Props = {
  className: string
  height?: number
  width?: number
}

export default function Handle({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="3.25"
        width="2.5"
        height="2.5"
        rx="1.25"
        fill={className}
      />
      <rect
        x="12.5"
        y="3.25"
        width="2.5"
        height="2.5"
        rx="1.25"
        fill={className}
      />
      <rect
        x="5"
        y="14.25"
        width="2.5"
        height="2.5"
        rx="1.25"
        fill={className}
      />
      <rect
        x="12.5"
        y="14.25"
        width="2.5"
        height="2.5"
        rx="1.25"
        fill={className}
      />
      <rect
        x="5"
        y="8.75"
        width="2.5"
        height="2.5"
        rx="1.25"
        fill={className}
      />
      <rect
        x="12.5"
        y="8.75"
        width="2.5"
        height="2.5"
        rx="1.25"
        fill={className}
      />
    </svg>
  )
}
