type Props = {
  className?: string
  height?: number
  width?: number
}

export default function LinkIcon({
  className,
  height = 20,
  width = 20,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.17233 8.58548L5.05101 10.7068C3.87944 11.8784 3.87944 13.7779 5.05101 14.9494V14.9494C6.22258 16.121 8.12208 16.121 9.29365 14.9494L11.415 12.8281"
        stroke="#8C8C8C"
        strokeWidth="1.25"
      />
      <path
        d="M12.8287 7.17127L7.17188 12.8281"
        stroke="#8C8C8C"
        strokeWidth="1.25"
      />
      <path
        d="M12.8277 11.4145L14.949 9.2932C16.1206 8.12162 16.1206 6.22213 14.949 5.05055V5.05055C13.7774 3.87898 11.8779 3.87898 10.7064 5.05055L8.58503 7.17188"
        stroke="#8C8C8C"
        strokeWidth="1.25"
      />
    </svg>
  )
}
