type Props = {
  className: string
  height?: number
  width?: number
}

export default function chevronRightLight({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7929 9.99998L7.14648 5.35353L7.85359 4.64642L13.2071 9.99998L7.85359 15.3535L7.14648 14.6464L11.7929 9.99998Z"
        fill={className}
      />
    </svg>
  )
}
