type Props = {
  className: string
  height?: number
  width?: number
}

export default function Queue({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="4" width="1.5" height="1.49993" fill={className} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 5.37494L7.5 5.37494L7.5 4.12494L16 4.12494L16 5.37494Z"
        fill={className}
      />
      <rect x="4" y="7.5" width="1.5" height="1.49993" fill={className} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8.87494L7.5 8.87494L7.5 7.62494L16 7.62494L16 8.87494Z"
        fill={className}
      />
      <rect x="4" y="11.0001" width="1.5" height="1.49993" fill={className} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 12.375L7.5 12.375L7.5 11.125L16 11.125L16 12.375Z"
        fill={className}
      />
      <rect x="4" y="14.5001" width="1.5" height="1.49993" fill={className} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 15.875L7.5 15.875L7.5 14.625L16 14.625L16 15.875Z"
        fill={className}
      />
    </svg>
  )
}
