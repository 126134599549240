type Props = {
  className: string
  height?: number
  width?: number
}

export default function Station({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 9.99999C4 7.91792 5.06051 6.08355 6.67082 5.00758L7.36427 6.04775C6.08951 6.89958 5.25 8.35174 5.25 9.99999C5.25 11.649 6.0903 13.1018 7.36606 13.9534L6.67261 14.9936C5.0613 13.9178 4 12.0828 4 9.99999Z"
        fill={className}
      />
      <path
        d="M13.3273 14.9936C14.9387 13.9178 16 12.0829 16 9.99999C16 7.9179 14.9395 6.08351 13.3291 5.00754L12.6357 6.04772C13.9105 6.89953 14.75 8.35172 14.75 9.99999C14.75 11.649 13.9097 13.1018 12.6339 13.9535L13.3273 14.9936Z"
        fill={className}
      />
      <path
        d="M11.8 10.0013C11.8 10.9954 10.9941 11.8013 10 11.8013C9.0059 11.8013 8.20001 10.9954 8.20001 10.0013C8.20001 9.00716 9.0059 8.20127 10 8.20127C10.9941 8.20127 11.8 9.00716 11.8 10.0013Z"
        fill={className}
      />
      <path
        d="M4.67388 2.01214C2.09708 3.73365 0.400024 6.66882 0.400024 10.0004C0.400024 13.3325 2.09771 16.2682 4.67532 17.9895L5.36877 16.9494C3.12671 15.4521 1.65002 12.8987 1.65002 10.0004C1.65002 7.10264 3.12608 4.54967 5.36732 3.05232L4.67388 2.01214Z"
        fill={className}
      />
      <path
        d="M14.6326 3.05226L15.3261 2.01208C17.9029 3.73358 19.6 6.66878 19.6 10.0004C19.6 13.3326 17.9023 16.2682 15.3246 17.9896L14.6312 16.9494C16.8733 15.4522 18.35 12.8987 18.35 10.0004C18.35 7.1026 16.8739 4.5496 14.6326 3.05226Z"
        fill={className}
      />
    </svg>
  )
}
