type Props = {
  className: string
  height?: number
  width?: number
}

export default function chevronLeftLight({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      className={`fill-${className}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.20707 10L12.8535 14.6465L12.1464 15.3536L6.79286 10L12.1464 4.64647L12.8535 5.35358L8.20707 10Z"
        fill={className}
      />
    </svg>
  )
}
