type Props = {
  className?: string
  height?: number
  width?: number
}

export default function ItalicIcon({
  className,
  height = 12,
  width = 5,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 5 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-${className}`}
    >
      <path
        d="M0.594477 11.1279H2.02248L4.05248 0.711929H2.62448L0.594477 11.1279Z"
        fill={className}
      />
    </svg>
  )
}
