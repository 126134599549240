type Props = {
  className: string
  height?: number
  width?: number
}

export default function Ellipsis({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="1.25" fill={className} />
      <circle cx="4.25" cy="10" r="1.25" fill={className} />
      <circle cx="15.75" cy="10" r="1.25" fill={className} />
    </svg>
  )
}
