type Props = {
  className: string
  height?: number
  width?: number
}

export default function Hamburger({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="9.42499" width="18" height="1.15" fill={className} />
      <rect x="1" y="16.85" width="18" height="1.15" fill={className} />
      <rect x="1" y="2" width="18" height="1.15" fill={className} />
    </svg>
  )
}
