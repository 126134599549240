type Props = {
  className?: string
  height?: number
  width?: number
}

export default function BoldIcon({
  className,
  height = 12,
  width = 10,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-${className}`}
    >
      <path
        d="M0.912273 0.711929H5.84027C6.91361 0.711929 7.72561 0.949929 8.27627 1.42593C8.86427 1.93926 9.15827 2.60193 9.15827 3.41393C9.15827 4.45926 8.59827 5.1546 7.47827 5.49993V5.55593C8.15027 5.7146 8.66361 6.01793 9.01827 6.46593C9.38227 6.9046 9.56427 7.44593 9.56427 8.08993C9.56427 8.59393 9.47561 9.0326 9.29827 9.40593C9.12094 9.77926 8.86894 10.0966 8.54227 10.3579C7.91694 10.8713 7.05827 11.1279 5.96627 11.1279H0.912273V0.711929ZM5.50427 4.89793C5.91494 4.89793 6.24161 4.8046 6.48427 4.61793C6.73627 4.42193 6.86227 4.12793 6.86227 3.73593C6.86227 3.35326 6.74094 3.06393 6.49827 2.86793C6.26494 2.6626 5.94294 2.55993 5.53227 2.55993H3.27827V4.89793H5.50427ZM5.71427 9.25193C6.18094 9.25193 6.54961 9.12593 6.82027 8.87393C7.07227 8.6406 7.19827 8.32326 7.19827 7.92193C7.19827 7.4646 7.05361 7.12393 6.76427 6.89993C6.47494 6.67593 6.11094 6.56393 5.67227 6.56393H3.27827V9.25193H5.71427Z"
        fill={className}
      />
    </svg>
  )
}
