type Props = {
  className: string
  height?: number
  width?: number
}

export default function VolumeMuted({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5454 10L12.0227 7.4773L12.9773 6.52271L15.5 9.04541L18.0227 6.52271L18.9773 7.4773L16.4546 10L18.9773 12.5227L18.0227 13.4773L15.5 10.9546L12.9773 13.4773L12.0227 12.5227L14.5454 10Z"
        fill={className}
      />
      <path d="M9.5 4V16L6 12.5H3V7.5H6L9.5 4Z" fill={className} />
    </svg>
  )
}
