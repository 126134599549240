type Props = {
  className: string
  height?: number
  width?: number
}

export default function ListView({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 1.625H0V0.375H20V1.625Z"
        fill={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 7.625H0V6.375H20V7.625Z"
        fill={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 13.625H0V12.375H20V13.625Z"
        fill={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 19.625H0V18.375H20V19.625Z"
        fill={className}
      />
    </svg>
  )
}
