type Props = {
  className: string
  height?: number
  width?: number
}

export default function Volume({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 4.00002V16L6 12.5H3V7.50002H6L9.5 4.00002Z"
        fill={className}
      />
      <path
        d="M15.2495 16.2263C16.863 14.6128 17.861 12.3838 17.861 9.92167C17.861 7.45954 16.863 5.23051 15.2495 3.617L14.2949 4.5716C15.6641 5.9408 16.511 7.83233 16.511 9.92167C16.511 12.011 15.6641 13.9025 14.295 15.2717L15.2495 16.2263Z"
        fill={className}
      />
      <path
        d="M13.0243 14.0013C14.0445 12.9753 14.675 11.5613 14.675 9.99998C14.675 8.43875 14.0446 7.02476 13.0244 5.99872L12.0698 6.95332C12.8457 7.73506 13.325 8.81154 13.325 9.99998C13.325 11.1885 12.8456 12.265 12.0697 13.0467L13.0243 14.0013Z"
        fill={className}
      />
    </svg>
  )
}
