type Props = {
  className: string
  height?: number
  width?: number
}

export default function PlaySmall({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 10L6 4V16L16 10Z" fill={className} />
    </svg>
  )
}
