type Props = {
  className: string
  height?: number
  width?: number
}

export default function SearchSmall({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 13.75C11.3995 13.75 13.75 11.3995 13.75 8.5C13.75 5.60051 11.3995 3.25 8.5 3.25C5.60051 3.25 3.25 5.60051 3.25 8.5C3.25 11.3995 5.60051 13.75 8.5 13.75ZM8.5 15C12.0899 15 15 12.0899 15 8.5C15 4.91015 12.0899 2 8.5 2C4.91015 2 2 4.91015 2 8.5C2 12.0899 4.91015 15 8.5 15Z"
        fill={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4419 12.558L18.4419 17.558L17.558 18.4419L12.558 13.4419L13.4419 12.558Z"
        fill={className}
      />
    </svg>
  )
}
