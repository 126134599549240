type Props = {
  className: string
  height?: number
  width?: number
}

export default function PauseLeftSmall({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className} stroke-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="7.68751"
        y="4.68751"
        width="1.62498"
        height="10.625"
        fill={className}
        stroke={className}
        strokeWidth="1.37502"
      />
      <rect
        x="0.687508"
        y="4.68751"
        width="1.62498"
        height="10.625"
        fill={className}
        stroke={className}
        strokeWidth="1.37502"
      />
    </svg>
  )
}
