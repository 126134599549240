type Props = {
  className: string
  height?: number
  width?: number
}

export default function CloseSmall({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55804 13.558L13.558 5.55804L14.4419 6.44193L6.44193 14.4419L5.55804 13.558Z"
        fill={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.558 14.442L5.55804 6.44196L6.44193 5.55807L14.4419 13.5581L13.558 14.442Z"
        fill={className}
      />
    </svg>
  )
}
