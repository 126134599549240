type Props = {
  className: string
  height?: number
  width?: number
}

export default function Lock({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.75 9.25H5.25V15.75H14.75V9.25ZM4 8V17H16V8H4Z"
        fill={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.625C8.50425 3.625 7.29169 4.83756 7.29169 6.33333V8H6.04169V6.33333C6.04169 4.14721 7.81389 2.375 10 2.375C12.1861 2.375 13.9584 4.14721 13.9584 6.33333V8H12.7084V6.33333C12.7084 4.83756 11.4958 3.625 10 3.625Z"
        fill={className}
      />
    </svg>
  )
}
