type Props = {
  className: string
  height?: number
  width?: number
}

export default function ChevronDownLight({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      className={`fill-${className}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99996 11.7929L14.6464 7.14642L15.3535 7.85353L9.99996 13.2071L4.64641 7.85353L5.35352 7.14642L9.99996 11.7929Z"
        fill={className}
      />
    </svg>
  )
}
