type Props = {
  className: string
  height?: number
  width?: number
}

export default function Repost({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9727 13.5749L14.9727 10L16.2227 10L16.2227 14.8249L7.19793 14.8249L7.19793 13.5749L14.9727 13.5749Z"
        fill={className}
      />
      <path
        d="M7.19922 17.0002L7.19922 11.4004L2.99936 14.2003L7.19922 17.0002Z"
        fill={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.02344 6.42514L5.02344 10L3.77344 10L3.77344 5.17514L12.7982 5.17514L12.7982 6.42514L5.02344 6.42514Z"
        fill={className}
      />
      <path
        d="M12.8008 2.99979L12.8008 8.59961L17.0006 5.7997L12.8008 2.99979Z"
        fill={className}
      />
    </svg>
  )
}
