type Props = {
  className: string
  height?: number
  width?: number
}

export default function Previous({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 10L16 16L16 4L6 10Z" fill={className} />
      <rect
        x="7"
        y="16"
        width="3"
        height="12"
        transform="rotate(180 7 16)"
        fill={className}
      />
    </svg>
  )
}
