type Props = {
  className: string
  height?: number
  width?: number
}

export default function CloseLarge({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.55804 17.558L17.558 1.55804L18.4419 2.44193L2.44193 18.4419L1.55804 17.558Z"
        fill={className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.558 18.442L1.55804 2.44196L2.44193 1.55807L18.4419 17.5581L17.558 18.442Z"
        fill={className}
      />
    </svg>
  )
}
