type Props = {
  className: string
  height?: number
  width?: number
}

export default function ChevronUpLight({ className, height, width }: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={`fill-${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8.20713L5.35359 12.8536L4.64648 12.1465L10 6.79292L15.3536 12.1465L14.6465 12.8536L10 8.20713Z"
        fill={className}
      />
    </svg>
  )
}
